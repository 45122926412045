import { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import GoogleMap, { Map } from "google-maps-react-markers";
import { geocode, OutputFormat, RequestType } from "react-geocode";
import Icon from "@mdi/react";
import { mdiAlphaHCircleOutline, mdiCogOutline, mdiMapSearch } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { ApiResponseType } from "../../entities/ApiResponseEntity";
import { ParametroModel } from "../../models/ParametroModel";

import Layout from "../../components/Layout";
import Marker from "../../components/Marker";

const toastTitle = "Configurações";

export default function ConfiguracoesFormulario() {
	const navigate = useNavigate();
	const { handleToast } = useToast();
	const { cliente, handleLogout } = useAuth();
	const { id, nome } = useParams();
	const apiService = new ApiService(handleLogout);

	const [formStatus, setFormStatus] = useState(false);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);
	const [formID, setFormID] = useState<number>();
	const [formClienteId, setFormClienteId] = useState<number>();
	const [formAlturaMinima, setFormAlturaMinima] = useState<number>();
	const [formAlturaRTH, setFormAlturaRTH] = useState<number>();
	const [formVelocidade, setFormVelocidade] = useState<number>();
	const [formBateriaMinima, setFormBateriaMinima] = useState<number>();
	const [formBateriaRCMinima, setFormBateriaRCMinima] = useState<number>();
	const [formBateriaMobileMinima, setFormBateriaMobileMinima] = useState<number>();
	const [formVentoMaximo, setFormVentoMaximo] = useState<number>();
	const [formNumeroMinimoGPS, setFormNumeroMinimoGPS] = useState<number>();
	const [formSinalMinimoGPS, setFormSinalMinimoGPS] = useState<number>();
	const [formHostStream, setFormHostStream] = useState<string>();
	const [formHostExternoStream, setFormHostExternoStream] = useState<string>();
	const [formEndereco, setFormEndereco] = useState("");
	const [formEnderecoLoading, setFormEnderecoLoading] = useState(false);
	const [formBaseLatitudeInicial, setFormBaseLatitudeInicial] = useState<number>();
	const [formBaseLongitudeInicial, setFormBaseLongitudeInicial] = useState<number>();
	const [formBaseLatitude, setFormBaseLatitude] = useState<number>();
	const [formBaseLongitude, setFormBaseLongitude] = useState<number>();
	const [formSOSLatitudeInicial, setFormSOSLatitudeInicial] = useState<number>();
	const [formSOSLongitudeInicial, setFormSOSLongitudeInicial] = useState<number>();
	const [formSOSLatitude, setFormSOSLatitude] = useState<number>();
	const [formSOSLongitude, setFormSOSLongitude] = useState<number>();
	const [formAtivarAI, setFormAtivarAI] = useState(false);
	const [formNivelDeteccao, setFormNivelDeteccao] = useState(1);
	const [formAtivarDAI, setFormAtivarDAI] = useState(false);
	const [formTemperatura, setFormTemperatura] = useState(35);
	const [formAtivarBloqueioDrone, setFormAtivarBloqueioDrone] = useState(true);
	const [formRthCancelamentoMissao, setFormRthCancelamentoMissao] = useState(true);
	const [formRthPausaMissao, setFormRthPausaMissao] = useState(true);
	const [formPermitirLoginOffline, setFormPermitirLoginOffline] = useState(false);
	const [formPermitirCarregarNovaMissao, setFormPermitirCarregarNovaMissao] = useState(false);
	const [formPermitirCriarMissaoVoando, setFormPermitirCriarMissaoVoando] = useState(false);
	const [formNivelEnvioDeAlertas, setFormNivelEnvioDeAlertas] = useState(4);
	const [formDesativarTodosLeds, setFormDesativarTodosLeds] = useState(false);
	const [formEnviarEmailAoFinal, setFormEnviarEmailAoFinal] = useState(false);
	const [formEmail, setFormEmail] = useState<string>("");
	const [formSetarFrequencias, setFormSetarFrequencias] = useState(false);
	const [formFrequenciaType, setFormFrequenciaType] = useState(0);
	const [formCanal, setFormCanal] = useState(0);
	const [formFixarPalleteCameraThermal, setFormFixarPalleteCameraThermal] = useState(false);
	const [formPalleteCameraThermal, setFormPalleteCameraThermal] = useState(1);
	const [formHabilitarStreaming, setFormHabilitarStreaming] = useState(true);
	const [formQualidadeDoVideoType, setFormQualidadeDoVideoType] = useState(1);
	const [formBitarate, setFormBitarate] = useState(1000000);
	const [formEnderecoVideoArmazenado, setFormEnderecoVideoArmazenado] = useState<string>("");
	const [formEnderecoVideoAoVivo, setFormEnderecoVideoAoVivo] = useState<string>("");

	const [formMap, setFormMap] = useState<google.maps.Map>();

	const { isLoading, isFetching, isRefetching, isFetched, refetch } = useQuery(["configuracoes", id ?? cliente!.id], () => fetchData(id ?? cliente!.id));
	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let resp = await apiService.GetParametros(id);
		if (resp.Result === 1 && resp.Data) {
			if (resp.Data?.first()) {
				setFormID(resp.Data.first().id);
				setFormClienteId(resp.Data.first().clienteId);
				setFormAlturaMinima(resp.Data.first().alturaMinima);
				setFormAlturaRTH(resp.Data.first().returnToHomeHeight);
				setFormVelocidade(resp.Data.first().velocidade);
				setFormBateriaMinima(resp.Data.first().bateriaMinima);
				setFormBateriaRCMinima(resp.Data.first().bateriaRCMinima);
				setFormBateriaMobileMinima(resp.Data.first().bateriaMobileMinima);
				setFormVentoMaximo(resp.Data.first().maximoVento);
				setFormNumeroMinimoGPS(resp.Data.first().gpsCountMinimo);
				setFormSinalMinimoGPS(resp.Data.first().gpsSinalMinimo);
				setFormHostStream(resp.Data.first().streamHost);
				setFormHostExternoStream(resp.Data.first().streamExternalHost);
				setFormBaseLatitudeInicial(resp.Data.first().baseLatitude);
				setFormBaseLongitudeInicial(resp.Data.first().baseLongitude);
				setFormBaseLatitude(resp.Data.first().baseLatitude);
				setFormBaseLongitude(resp.Data.first().baseLongitude);
				setFormSOSLatitudeInicial(resp.Data.first().sosLatitude);
				setFormSOSLongitudeInicial(resp.Data.first().sosLongitude);
				setFormSOSLatitude(resp.Data.first().sosLatitude);
				setFormSOSLongitude(resp.Data.first().sosLongitude);
				setFormAtivarAI(resp.Data.first().ativarAI);
				setFormNivelDeteccao(resp.Data.first().nivelDeteccao);
				setFormAtivarDAI(resp.Data.first().ativarDAI);
				setFormTemperatura(resp.Data.first().temperatura);
				setFormAtivarBloqueioDrone(resp.Data.first().ativarBloqueioDrone);
				setFormRthCancelamentoMissao(resp.Data.first().rthCancelamentoMissao);
				setFormRthPausaMissao(resp.Data.first().rthPausaMissao);
				setFormPermitirLoginOffline(resp.Data.first().permitirLoginOffline);
				setFormPermitirCarregarNovaMissao(resp.Data.first().permitirCarregarNovaMissao);
				setFormPermitirCriarMissaoVoando(resp.Data.first().permitirCriarMissaoVoando);
				setFormNivelEnvioDeAlertas(resp.Data.first().nivelEnvioDeAlertas);
				setFormDesativarTodosLeds(resp.Data.first().desativarTodosLeds);
				setFormSetarFrequencias(resp.Data.first().setarFrequencias);
				setFormFrequenciaType(resp.Data.first().frequenciaType);
				setFormCanal(resp.Data.first().canal);
				setFormFixarPalleteCameraThermal(resp.Data.first().fixarPalleteCameraThermal);
				setFormPalleteCameraThermal(resp.Data.first().palleteCameraThermal);
				setFormHabilitarStreaming(resp.Data.first().habilitarStreaming);
				setFormQualidadeDoVideoType(resp.Data.first().qualidadeDoVideoType);
				setFormBitarate(resp.Data.first().bitarate);
				setFormEnderecoVideoArmazenado(resp.Data.first().enderecoVideoArmazenado);
				setFormEnderecoVideoAoVivo(resp.Data.first().enderecoVideoAoVivo);
				setFormEnviarEmailAoFinal(resp.Data.first().enviarEmailAoFinal);
				setFormEmail(resp.Data.first().email);

				if (resp.Data.first().baseLatitude && resp.Data.first().baseLongitude) {
					formMap?.setCenter({ lat: resp.Data.first().baseLatitude, lng: resp.Data.first().baseLongitude });
				}
			}
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return true;
	}

	async function mutateData(data: ParametroModel) {
		return await apiService.SetParametros(id ? Number(id) : cliente!.id, data);
	}

	function mutateSuccess(resp: ApiResponseType<number>) {
		if (resp.Result === 1 && resp.Data) {
			queryClient.invalidateQueries(["configuracoes", id ?? cliente!.id]);
			handleToast(toastTitle, resp.Message, 5000);
			navigate("/configuracoesFormulario");
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/");
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		setFormSaving(true);

		const data: ParametroModel = {
			id: Number(formID) ?? null,
			clienteId: Number(formClienteId) ?? null,
			alturaMinima: formAlturaMinima!,
			returnToHomeHeight: formAlturaRTH!,
			velocidade: formVelocidade!,
			bateriaMinima: formBateriaMinima!,
			bateriaRCMinima: formBateriaRCMinima!,
			bateriaMobileMinima: formBateriaMobileMinima!,
			maximoVento: formVentoMaximo!,
			gpsCountMinimo: formNumeroMinimoGPS!,
			gpsSinalMinimo: formSinalMinimoGPS!,
			streamHost: formHostStream!,
			streamExternalHost: formHostExternoStream!,
			baseLatitude: formBaseLatitude!,
			baseLongitude: formBaseLongitude!,
			sosLatitude: formSOSLatitude!,
			sosLongitude: formSOSLongitude!,
			ativarAI: formAtivarAI,
			nivelDeteccao: formNivelDeteccao,
			ativarDAI: formAtivarDAI,
			temperatura: formTemperatura,
			ativarBloqueioDrone: formAtivarBloqueioDrone,
			rthCancelamentoMissao: formRthCancelamentoMissao,
			rthPausaMissao: formRthPausaMissao,
			permitirLoginOffline: formPermitirLoginOffline,
			permitirCarregarNovaMissao: formPermitirCarregarNovaMissao,
			permitirCriarMissaoVoando: formPermitirCriarMissaoVoando,
			nivelEnvioDeAlertas: formNivelEnvioDeAlertas,
			desativarTodosLeds: formDesativarTodosLeds,
			setarFrequencias: formSetarFrequencias,
			frequenciaType: formFrequenciaType,
			canal: formCanal,
			fixarPalleteCameraThermal: formFixarPalleteCameraThermal,
			palleteCameraThermal: formPalleteCameraThermal,
			habilitarStreaming: formHabilitarStreaming,
			qualidadeDoVideoType: formQualidadeDoVideoType,
			bitarate: formBitarate,
			enderecoVideoArmazenado: formEnderecoVideoArmazenado,
			enderecoVideoAoVivo: formEnderecoVideoAoVivo,
			email: formEmail,
			enviarEmailAoFinal: formEnviarEmailAoFinal,
		};

		mutation.mutate(data);
	}

	function handleMapLoad({ map }: { map: Map; maps: any }) {
		var baseLatitude = formBaseLatitude;
		var baseLongitude = formBaseLongitude;
		var sosLatitude = formSOSLatitude;
		var sosLongitude = formSOSLongitude;

		map.addListener("click", (e: any) => {
			if (!baseLatitude && !baseLongitude) {
				baseLatitude = e.latLng.lat();
				baseLongitude = e.latLng.lng();
				setFormBaseLatitudeInicial(baseLatitude);
				setFormBaseLongitudeInicial(baseLongitude);
				setFormBaseLatitude(baseLatitude);
				setFormBaseLongitude(baseLongitude);
			} else if (!sosLatitude && !sosLongitude) {
				sosLatitude = e.latLng.lat();
				sosLongitude = e.latLng.lng();
				setFormSOSLatitudeInicial(sosLatitude);
				setFormSOSLongitudeInicial(sosLongitude);
				setFormSOSLatitude(sosLatitude);
				setFormSOSLongitude(sosLongitude);
			}
		});

		setFormMap(map);
	}

	function handleEndereco() {
		setFormEnderecoLoading(true);
		geocode(RequestType.ADDRESS, formEndereco, { key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`, outputFormat: OutputFormat.JSON })
			.then((response) => {
				let location = response.results[0].geometry.location;
				formMap!.setCenter({ lat: location.lat, lng: location.lng });
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setFormEnderecoLoading(false);
			});
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Icon path={mdiCogOutline} size={1} className="me-1" />
				Formulário de Configurações{nome && <span className="ms-2 small  text-muted">{nome}</span>}
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>
			<Form onSubmit={handleSubmit}>
				<Card className="mb-3">
					<Card.Body>
						<Form.Group className="mb-3" controlId="alturaMinima">
							<Form.Label>Altura Mínima</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="number"
									min={1}
									placeholder="Informe aqui a altura mínima"
									value={formAlturaMinima}
									onChange={(event) => {
										setFormAlturaMinima(Number(event.target.value));
									}}
									required={true}
									disabled={!formStatus}
								/>
								<InputGroup.Text>m</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3" controlId="alturaRTH">
							<Form.Label>Altura RTH (Return To Home)</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="number"
									min={1}
									placeholder="Informe aqui o altura rth"
									value={formAlturaRTH}
									onChange={(event) => {
										setFormAlturaRTH(Number(event.target.value));
									}}
									disabled={!formStatus}
								/>
								<InputGroup.Text>m</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3" controlId="velocidade">
							<Form.Label>Velocidade</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="number"
									min={1}
									placeholder="Informe aqui a velocidade"
									value={formVelocidade}
									onChange={(event) => {
										setFormVelocidade(Number(event.target.value));
									}}
									disabled={!formStatus}
								/>
								<InputGroup.Text>m/s</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3" controlId="bateriaMinima">
							<Form.Label>Bateria Mínima</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="number"
									min={1}
									max={100}
									placeholder="Informe aqui a bateria mínima"
									value={formBateriaMinima}
									onChange={(event) => {
										setFormBateriaMinima(Number(event.target.value));
									}}
									disabled={!formStatus}
								/>
								<InputGroup.Text>%</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3" controlId="bateriaRCMinima">
							<Form.Label>Bateria RC Mínima</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="number"
									min={1}
									max={100}
									placeholder="Informe aqui a bateria rc mínima"
									value={formBateriaRCMinima}
									onChange={(event) => {
										setFormBateriaRCMinima(Number(event.target.value));
									}}
									disabled={!formStatus}
								/>
								<InputGroup.Text>%</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3" controlId="bateriaMobileMinima">
							<Form.Label>Bateria Mobile Mínima</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="number"
									min={1}
									max={100}
									placeholder="Informe aqui a bateria mobile mínima"
									value={formBateriaMobileMinima}
									onChange={(event) => {
										setFormBateriaMobileMinima(Number(event.target.value));
									}}
									disabled={!formStatus}
								/>
								<InputGroup.Text>%</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3" controlId="ventoMaximo">
							<Form.Label>Vento Máximo</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="number"
									min={1}
									placeholder="Informe aqui o vento máximo"
									value={formVentoMaximo}
									onChange={(event) => {
										setFormVentoMaximo(Number(event.target.value));
									}}
									disabled={!formStatus}
								/>
								<InputGroup.Text>km/h</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3" controlId="numeroMinimoGPS">
							<Form.Label>Número Mínimo de GPS</Form.Label>
							<Form.Control
								type="number"
								min={1}
								placeholder="Informe aqui o número mínimo de gps"
								value={formNumeroMinimoGPS}
								onChange={(event) => {
									setFormNumeroMinimoGPS(Number(event.target.value));
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="sinalMinimoGPS">
							<Form.Label>Sinal Mínimo de GPS</Form.Label>
							<Form.Range
								value={formSinalMinimoGPS}
								min={1}
								max={5}
								onChange={(event) => {
									setFormSinalMinimoGPS(Number(event.target.value));
								}}
								disabled={!formStatus}
							/>
							<div className="d-flex justify-content-between" style={{ marginTop: "-0.5rem", marginLeft: 4, marginRight: 4 }}>
								<span>1</span>
								<span>2</span>
								<span>3</span>
								<span>4</span>
								<span>5</span>
							</div>
						</Form.Group>
						<Form.Group className="mb-3" controlId="hostStream">
							<Form.Label>Host Stream</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o host stream"
								value={formHostStream}
								onChange={(event) => {
									setFormHostStream(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="hostExternoStream">
							<Form.Label>Host Externo Stream</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o host externo stream"
								value={formHostExternoStream}
								onChange={(event) => {
									setFormHostExternoStream(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="" controlId="homepoint">
							<Form.Label>Homepoint / SOS</Form.Label>
							<InputGroup>
								<Form.Control
									type="text"
									placeholder="Informe aqui o endereço"
									value={formEndereco}
									onChange={(event) => {
										setFormEndereco(event.target.value);
									}}
									disabled={!formStatus}
								/>
								<Button onClick={handleEndereco} disabled={!formStatus || formEnderecoLoading}>
									{formEnderecoLoading ? <Spinner size="sm" /> : <Icon path={mdiMapSearch} size={1} />}
								</Button>
							</InputGroup>
							<div className={`position-relative img-thumbnail`} style={{ height: 500, background: !formStatus ? "var(--bs-secondary-bg)" : "" }}>
								{!isFetching && isFetched && (
									<GoogleMap
										apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
										defaultZoom={15}
										defaultCenter={{ lat: formBaseLatitude ?? -30.033056, lng: formBaseLongitude ?? -51.23 }}
										onGoogleApiLoaded={handleMapLoad}
									>
										{formBaseLatitudeInicial && formBaseLongitudeInicial && (
											<Marker
												lat={formBaseLatitudeInicial}
												lng={formBaseLongitudeInicial}
												draggable={true}
												onDragEnd={(e: any, { latLng }: any) => {
													setFormBaseLatitude(latLng.lat);
													setFormBaseLongitude(latLng.lng);
												}}
											>
												<Icon
													path={mdiAlphaHCircleOutline}
													size={1.5}
													className="text-danger bg-white rounded-circle"
													style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }}
												/>
											</Marker>
										)}
										{formSOSLatitudeInicial && formSOSLongitudeInicial && (
											<Marker
												lat={formSOSLatitudeInicial}
												lng={formSOSLongitudeInicial}
												draggable={true}
												onDragEnd={(e: any, { latLng }: any) => {
													setFormSOSLatitude(latLng.lat);
													setFormSOSLongitude(latLng.lng);
												}}
											>
												<Icon
													path={mdiAlphaHCircleOutline}
													size={1.5}
													className="text-air-blue bg-white rounded-circle"
													style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }}
												/>
											</Marker>
										)}
									</GoogleMap>
								)}
							</div>
							<Form.Text className="d-flex align-items-center">
								Clique no local desejado no mapa para adicionar o
								<Icon path={mdiAlphaHCircleOutline} size={1} className="text-danger ms-1" /> <span className="fw-bold">Homepoint</span>&nbsp;e o
								<Icon path={mdiAlphaHCircleOutline} size={1} className="text-air-blue ms-1" /> <span className="fw-bold">SOS</span>
							</Form.Text>
						</Form.Group>
					</Card.Body>
				</Card>
				<Card className="mb-3">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Inteligência Artificial</Card.Header>
					<Card.Body className="row">
						<Form.Group className="mb-3 col-md-6" controlId="ativarAI">
							<Form.Label>Ativar IA</Form.Label>
							<Form.Check
								type="switch"
								label={formAtivarAI ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formAtivarAI}
								onChange={(e) => {
									setFormAtivarAI(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3  col-md-6" controlId="nivelDeteccao">
							<Form.Label>Nível de detecção</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui a altura mínima"
								value={formNivelDeteccao}
								onChange={(event) => {
									setFormNivelDeteccao(Number(event.target.value));
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
					</Card.Body>
				</Card>
				<Card className="mb-3">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Dispositivo de Aquisição de Imagens</Card.Header>
					<Card.Body className="row">
						<Form.Group className="mb-3 col-md-6" controlId="ativarDAI">
							<Form.Label>Ativar DAI</Form.Label>
							<Form.Check
								type="switch"
								label={formAtivarDAI ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formAtivarDAI}
								onChange={(e) => {
									setFormAtivarDAI(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3  col-md-6" controlId="temperatura">
							<Form.Label>Temperatura mínima para aviso</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="number"
									placeholder="Informe aqui a temperatura mínima para aviso"
									value={formTemperatura}
									onChange={(event) => {
										setFormTemperatura(Number(event.target.value));
									}}
									disabled={!formStatus}
								/>
								<InputGroup.Text>°C</InputGroup.Text>
							</InputGroup>
						</Form.Group>
					</Card.Body>
				</Card>
				<Card className="mb-3">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Configurações</Card.Header>
					<Card.Body className="row">
						<Form.Group className="mb-3 col-md-6" controlId="ativarBloqueioDrone">
							<Form.Label>Ativar bloqueio do drone</Form.Label>
							<Form.Check
								type="switch"
								label={formAtivarBloqueioDrone ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formAtivarBloqueioDrone}
								onChange={(e) => {
									setFormAtivarBloqueioDrone(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="rthCancelamentoMissao">
							<Form.Label>Ativar RTH em caso de pausa da missão</Form.Label>
							<Form.Check
								type="switch"
								label={formRthCancelamentoMissao ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formRthCancelamentoMissao}
								onChange={(e) => {
									setFormRthCancelamentoMissao(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="rthPausaMissao">
							<Form.Label>Ativar RTH em caso de cancelamento de missão</Form.Label>
							<Form.Check
								type="switch"
								label={formRthPausaMissao ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formRthPausaMissao}
								onChange={(e) => {
									setFormRthPausaMissao(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="permitirLoginOffline">
							<Form.Label>Permitir login offline</Form.Label>
							<Form.Check
								type="switch"
								label={formPermitirLoginOffline ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formPermitirLoginOffline}
								onChange={(e) => {
									setFormPermitirLoginOffline(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="permitirCarregarNovaMissao">
							<Form.Label>Permitir carregar nova missão durante execução de missão</Form.Label>
							<Form.Check
								type="switch"
								label={formPermitirCarregarNovaMissao ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formPermitirCarregarNovaMissao}
								onChange={(e) => {
									setFormPermitirCarregarNovaMissao(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="permitirCriarMissaoVoando">
							<Form.Label>Permitir criar missão voando</Form.Label>
							<Form.Check
								type="switch"
								label={formPermitirCriarMissaoVoando ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formPermitirCriarMissaoVoando}
								onChange={(e) => {
									setFormPermitirCriarMissaoVoando(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3  col-md-6" controlId="nivelEnvioDeAlertas">
							<Form.Label>Nível mínimo para envio de alertas</Form.Label>
							<Form.Range
								value={formNivelEnvioDeAlertas}
								min={1}
								max={5}
								onChange={(event) => {
									setFormNivelEnvioDeAlertas(Number(event.target.value));
								}}
								disabled={!formStatus}
							/>
							<div className="d-flex justify-content-between" style={{ marginTop: "-0.5rem", marginLeft: 4, marginRight: 4 }}>
								<span>1</span>
								<span>2</span>
								<span>3</span>
								<span>4</span>
								<span>5</span>
							</div>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="desativarTodosLeds">
							<Form.Label>Desabilitar todos os LEDs do drone (modo furtivo)</Form.Label>
							<Form.Check
								type="switch"
								label={formDesativarTodosLeds ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formDesativarTodosLeds}
								onChange={(e) => {
									setFormDesativarTodosLeds(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="enviarEmailAoFinal">
							<Form.Label>Enviar e-mail ao final do voo com as fotos</Form.Label>
							<Form.Check
								type="switch"
								label={formEnviarEmailAoFinal ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formEnviarEmailAoFinal}
								onChange={(e) => {
									setFormEnviarEmailAoFinal(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						{formEnviarEmailAoFinal && (
							<Form.Group className="mb-3 col-md-6" controlId="email">
								<Form.Label>E-mail</Form.Label>
								<Form.Control
									type="email"
									placeholder="Informe aqui o email"
									value={formEmail}
									onChange={(event) => {
										setFormEmail(event.target.value);
									}}
									disabled={!formStatus}
								/>
							</Form.Group>
						)}
					</Card.Body>
				</Card>
				<Card className="mb-3">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Frequências</Card.Header>
					<Card.Body className="row">
						<Form.Group className="mb-3 col-md-6" controlId="setarFrequencias">
							<Form.Label>Setar frequências</Form.Label>
							<Form.Check
								type="switch"
								label={formSetarFrequencias ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formSetarFrequencias}
								onChange={(e) => {
									setFormSetarFrequencias(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6">
							<Form.Label>Frequências</Form.Label>
							<div className="inline-radio">
								<Form.Check
									type="radio"
									label={"DUAL"}
									name="frequenciaType"
									id={`frequenciaType-0`}
									className="d-inline-flex gap-2"
									value={0}
									inline={true}
									checked={formFrequenciaType === 0}
									onChange={(e) => {
										setFormFrequenciaType(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
								<Form.Check
									type="radio"
									label={"2.4MHz"}
									name="frequenciaType"
									id={`frequenciaType-1`}
									className="d-inline-flex gap-2"
									value={1}
									inline={true}
									checked={formFrequenciaType === 1}
									onChange={(e) => {
										setFormFrequenciaType(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
								<Form.Check
									type="radio"
									label={"5.8MHz"}
									name="frequenciaType"
									id={`frequenciaType-2`}
									className="d-inline-flex gap-2"
									value={2}
									inline={true}
									checked={formFrequenciaType === 2}
									onChange={(e) => {
										setFormFrequenciaType(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
							</div>
						</Form.Group>
						<Form.Group className="mb-3 col-md-12" controlId="canal">
							<Form.Label>Canal</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui o canal"
								value={formCanal}
								onChange={(event) => {
									setFormCanal(Number(event.target.value));
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
					</Card.Body>
				</Card>
				<Card className="mb-3">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Camera Thermal</Card.Header>
					<Card.Body className="row">
						<Form.Group className="mb-3 col-md-6" controlId="fixarPalleteCameraThermal">
							<Form.Label>Fixar Pallete da camera thermal</Form.Label>
							<Form.Check
								type="switch"
								label={formFixarPalleteCameraThermal ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formFixarPalleteCameraThermal}
								onChange={(e) => {
									setFormFixarPalleteCameraThermal(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3  col-md-6" controlId="palleteCameraThermal">
							<Form.Label>Pallete camera thermal</Form.Label>
							<Form.Range
								value={formPalleteCameraThermal}
								min={1}
								max={10}
								onChange={(event) => {
									setFormPalleteCameraThermal(Number(event.target.value));
								}}
								disabled={!formStatus}
							/>
							<div className="d-flex justify-content-between" style={{ marginTop: "-0.5rem", marginLeft: 4, marginRight: 4 }}>
								<span>1</span>
								<span>2</span>
								<span>3</span>
								<span>4</span>
								<span>5</span>
								<span>6</span>
								<span>7</span>
								<span>8</span>
								<span>9</span>
								<span>10</span>
							</div>
						</Form.Group>
					</Card.Body>
				</Card>
				<Card className="mb-3">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Streaming</Card.Header>
					<Card.Body className="row">
						<Form.Group className="mb-3 col-md-6" controlId="habilitarStreaming">
							<Form.Label>Habilitar Streaming</Form.Label>
							<Form.Check
								type="switch"
								label={formHabilitarStreaming ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formHabilitarStreaming}
								onChange={(e) => {
									setFormHabilitarStreaming(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6">
							<Form.Label>Qualidade do video</Form.Label>
							<div className="inline-radio">
								<Form.Check
									type="radio"
									label={"SD"}
									name="qualidadeDoVideoType"
									id={`qualidadeDoVideoType-1`}
									className="d-inline-flex gap-2"
									value={1}
									inline={true}
									checked={formQualidadeDoVideoType === 1}
									onChange={(e) => {
										setFormQualidadeDoVideoType(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
								<Form.Check
									type="radio"
									label={"HD"}
									name="qualidadeDoVideoType"
									id={`qualidadeDoVideoType-2`}
									className="d-inline-flex gap-2"
									value={2}
									inline={true}
									checked={formQualidadeDoVideoType === 2}
									onChange={(e) => {
										setFormQualidadeDoVideoType(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
								<Form.Check
									type="radio"
									label={"FULLHD"}
									name="qualidadeDoVideoType"
									id={`qualidadeDoVideoType-3`}
									className="d-inline-flex gap-2"
									value={3}
									inline={true}
									checked={formQualidadeDoVideoType === 3}
									onChange={(e) => {
										setFormQualidadeDoVideoType(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
								<Form.Check
									type="radio"
									label={"4K"}
									name="qualidadeDoVideoType"
									id={`qualidadeDoVideoType-4`}
									className="d-inline-flex gap-2"
									value={3}
									inline={true}
									checked={formQualidadeDoVideoType === 4}
									onChange={(e) => {
										setFormQualidadeDoVideoType(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
							</div>
						</Form.Group>
						<Form.Group className="mb-3 col-md-12">
							<Form.Label>Bitrate</Form.Label>
							<div className="inline-radio">
								<Form.Check
									type="radio"
									label={"1.000.000"}
									name="bitarate"
									id={`bitarate-1`}
									className="d-inline-flex gap-2"
									value={1000000}
									inline={true}
									checked={formBitarate === 1000000}
									onChange={(e) => {
										setFormBitarate(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
								<Form.Check
									type="radio"
									label={"1.500.000"}
									name="bitarate"
									id={`bitarate-2`}
									className="d-inline-flex gap-2"
									value={1500000}
									inline={true}
									checked={formBitarate === 1500000}
									onChange={(e) => {
										setFormBitarate(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
								<Form.Check
									type="radio"
									label={"2.000.000"}
									name="bitarate"
									id={`bitarate-3`}
									className="d-inline-flex gap-2"
									value={2000000}
									inline={true}
									checked={formBitarate === 2000000}
									onChange={(e) => {
										setFormBitarate(Number(e.currentTarget.value));
									}}
									disabled={!formStatus}
								/>
							</div>
						</Form.Group>
						<Form.Group className="mb-3" controlId="enderecoVideoArmazenado">
							<Form.Label>Endereço servidor para play video armazenado</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o endereço servidor para play video armazenado"
								value={formEnderecoVideoArmazenado}
								onChange={(event) => {
									setFormEnderecoVideoArmazenado(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="enderecoVideoAoVivo">
							<Form.Label>Endereço servidor para play video ao vivo</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o endereço servidor para play video ao vivo"
								value={formEnderecoVideoAoVivo}
								onChange={(event) => {
									setFormEnderecoVideoAoVivo(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
					</Card.Body>
				</Card>
				<div className="mb-4">
					{!formStatus ? (
						<Button
							key={"editar"}
							className="me-2"
							variant="dark"
							type="button"
							onClick={() => {
								setFormStatus(true);
							}}
						>
							Editar Informações
						</Button>
					) : (
						<>
							<Button key={"salvar"} className="me-2 text-white" variant="air-blue" type="submit" disabled={formSaving}>
								{formSaving ? (
									<>
										<Spinner animation="border" size="sm" className="me-2" /> Salvando
									</>
								) : (
									"Salvar Informações"
								)}
							</Button>
							<Button key={"cancelar"} className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
								Cancelar
							</Button>
						</>
					)}
				</div>
			</Form>
		</Layout>
	);
}
